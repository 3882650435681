"use strict";
/*!
 *
 *  slide-scroll.ts
 *
 */
Object.defineProperty(exports, "__esModule", { value: true });
;
var initial = {
    easing: 'fast',
    speed: 500,
    not: null
};
exports.default = (function (option) {
    var setting = $.extend(initial, option);
    var link = 'a[href^="#"]:not(' + setting.not + '):not([href="#"]), a[href^="' + location.pathname + '#"], a[href^="' + location.protocol + '//' + location.hostname + location.pathname + '#"]';
    $(document).on('click', link, function (e) {
        e.preventDefault();
        $.fancybox.close();
        var target = $(e.currentTarget).attr('href');
        if (target !== undefined) {
            target = target.replace(location.protocol + '//' + location.hostname + location.pathname, '').replace(location.pathname, '');
            var $target = $(target);
            if ($target.is('.c-Tab_Contents')) {
                $target = $target.closest('.c-Tab');
                $target.find('.c-Tab_Nav_Item').filter(function (i, elem) {
                    return $(elem).attr('href') === target;
                }).trigger('click');
            }
            ;
            var offset = $target.offset();
            var top_1 = offset !== undefined ? offset.top : 0;
            $(util.scroller).animate({
                scrollTop: top_1 - util.scrollGap
            }, {
                duration: setting.speed,
                easing: setting.easing,
                queue: false
            });
        }
        ;
    });
});
