var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "mtc-Nav" },
    [
      _c("div", { staticClass: "mtc-Nav_Return" }, [
        _c(
          "a",
          {
            attrs: { href: "#" },
            on: {
              click: function($event) {
                $event.preventDefault()
                return _vm.prev.apply(null, arguments)
              }
            }
          },
          [_vm._v("戻る")]
        )
      ]),
      _vm._v(" "),
      _c("transition", { attrs: { name: "fade" } }, [
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.state === 5,
                expression: "state === 5"
              }
            ],
            staticClass: "mtc-Nav_More"
          },
          [
            _c(
              "a",
              {
                attrs: { href: "#" },
                on: {
                  click: function($event) {
                    $event.preventDefault()
                    return _vm.reset.apply(null, arguments)
                  }
                }
              },
              [_vm._v("もう一度診断する")]
            )
          ]
        )
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }