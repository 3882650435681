var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "mtc-Step" }, [
    _c("ol", { staticClass: "mtc-Step_List", style: _vm.position }, [
      _vm._m(0),
      _vm._v(" "),
      _c(
        "li",
        {
          class: {
            "mtc-Step_Item": true,
            "-active": _vm.state >= 0,
            "-current": _vm.state === 0
          }
        },
        [
          _c("span", { staticClass: "mtc-Step_Item_Label" }, [
            _vm._v("車のタイプ")
          ])
        ]
      ),
      _vm._v(" "),
      _c(
        "li",
        {
          class: {
            "mtc-Step_Item": true,
            "-active": _vm.state >= 1,
            "-current": _vm.state === 1
          }
        },
        [
          _c("span", { staticClass: "mtc-Step_Item_Label" }, [
            _vm._v("乗車頻度")
          ])
        ]
      ),
      _vm._v(" "),
      _c(
        "li",
        {
          class: {
            "mtc-Step_Item": true,
            "-active": _vm.state >= 2,
            "-current": _vm.state === 2
          }
        },
        [
          _c("span", { staticClass: "mtc-Step_Item_Label" }, [
            _vm._v("保管場所")
          ])
        ]
      ),
      _vm._v(" "),
      _c(
        "li",
        {
          class: {
            "mtc-Step_Item": true,
            "-active": _vm.state >= 3,
            "-current": _vm.state === 3
          }
        },
        [
          _c("span", { staticClass: "mtc-Step_Item_Label" }, [
            _vm._v("洗車頻度")
          ])
        ]
      ),
      _vm._v(" "),
      _c(
        "li",
        {
          class: {
            "mtc-Step_Item": true,
            "-active": _vm.state >= 4,
            "-current": _vm.state === 4
          }
        },
        [
          _c("span", { staticClass: "mtc-Step_Item_Label" }, [
            _vm._v("洗車方法")
          ])
        ]
      ),
      _vm._v(" "),
      _c(
        "li",
        {
          class: {
            "mtc-Step_Item": true,
            "-active": _vm.state >= 5,
            "-current": _vm.state === 5
          }
        },
        [_c("span", { staticClass: "mtc-Step_Item_Label" }, [_vm._v("結果")])]
      )
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("li", { staticClass: "mtc-Step_Item" }, [
      _c("span", { staticClass: "mtc-Step_Item_Label" }, [_vm._v("Start")])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }