var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "section",
    { class: { "mtc-Question": true, "-current": _vm.state === _vm.num } },
    [
      _c("h2", { staticClass: "mtc-Question_Title" }, [
        _vm._v(_vm._s(_vm.q[_vm.num].label))
      ]),
      _vm._v(" "),
      _c(
        "ul",
        { staticClass: "mtc-Question_List" },
        _vm._l(_vm.q[_vm.num].a, function(item) {
          return _c(
            "li",
            { key: item.label, staticClass: "mtc-Question_Item mtc-Card" },
            [
              _c("label", { staticClass: "mtc-Card_Label" }, [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.current,
                      expression: "current"
                    }
                  ],
                  staticClass: "mtc-Card_Radio",
                  attrs: { type: "radio", name: _vm.inputName },
                  domProps: {
                    value: item.value,
                    checked: _vm._q(_vm.current, item.value)
                  },
                  on: {
                    change: function($event) {
                      _vm.current = item.value
                    }
                  }
                }),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    class: {
                      "mtc-Card_Body": true,
                      "-noselect":
                        _vm.current !== "" && _vm.current !== item.value
                    },
                    on: {
                      click: function($event) {
                        return _vm.selected(item.value)
                      }
                    }
                  },
                  [
                    _c("div", { staticClass: "mtc-Card_Thumb" }, [
                      _c("img", {
                        attrs: {
                          src: _vm.getSrc(item.value),
                          alt: "",
                          width: "370",
                          height: "230",
                          loading: "lazy"
                        }
                      })
                    ]),
                    _vm._v(" "),
                    _c("div", {
                      staticClass: "mtc-Card_Title",
                      domProps: { innerHTML: _vm._s(item.label) }
                    })
                  ]
                )
              ])
            ]
          )
        }),
        0
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }