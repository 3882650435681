<template>
	<section :class="{'mtc-Question': true, '-current': state === num}">
		<h2 class="mtc-Question_Title">{{ q[num].label }}</h2>
		<ul class="mtc-Question_List">
			<li class="mtc-Question_Item mtc-Card" v-for="item in q[num].a" :key="item.label">
				<label class="mtc-Card_Label">
					<input type="radio" :name="inputName" :value="item.value" v-model="current" class="mtc-Card_Radio">
					<div :class="{'mtc-Card_Body': true, '-noselect': current !== '' && current !== item.value}" @click="selected(item.value)">
						<div class="mtc-Card_Thumb"><img :src="getSrc(item.value)" alt="" width="370" height="230" loading="lazy"></div>
						<div class="mtc-Card_Title" v-html="item.label"></div>
					</div>
				</label>
			</li>
		</ul>
	</section>
</template>

<script>
	export default {
		props: ['a', 'num', 'state'],
		data: function() {
			return {
				current: this.a,
				q: [
					{
						label: '車のタイプをお選びください',
						a: [
							{
								label: 'SUV・ワンボックス<br>などの大型車',
								value: 'A'
							},
							{
								label: 'セダン・スポーツカー<br>などの中型車',
								value: 'B'
							},
							{
								label: 'コンパクトカー・軽自動車<br>などの小型車',
								value: 'C'
							}
						]
					},
					{
						label: '普段車をよく使いますか？',
						a: [
							{
								label: 'ほぼ毎日使う',
								value: 'A'
							},
							{
								label: '週末だけ',
								value: 'B'
							}
						]
					},
					{
						label: '保管場所をお選びください',
						a: [
							{
								label: '屋根あり',
								value: 'A'
							},
							{
								label: '屋根なし',
								value: 'B'
							}
						]
					},
					{
						label: '洗車はどの程度しますか？',
						a: [
							{
								label: '1か月に一度',
								value: 'A'
							},
							{
								label: '3か月に一度',
								value: 'B'
							}
						]
					},
					{
						label: '洗車方法は主にどっち',
						a: [
							{
								label: '自分で手洗い',
								value: 'A'
							},
							{
								label: '洗車機を利用',
								value: 'B'
							}
						]
					}
				]
			}
		},
		computed: {
			inputName: function() {
				return 'q' + this.num;
			}
		},
		watch: {
			a: function() {
				this.current = this.a;
			}
		},
		methods: {
			getSrc: function(val) {
				return '/assets/img/match/question_' + (this.num + 1) + '_' + val.toLowerCase() + '.png'
			},
			selected: function(val) {
				this.$emit('set', val);
				this.$emit('next');
			}
		}
	}
</script>