"use strict";
/*!
 *
 *  fit-height.ts
 *
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.FitHeight = void 0;
var FitHeight = /** @class */ (function () {
    function FitHeight(elem, option) {
        var _this = this;
        this.default = {
            target: undefined,
            column: 2,
            responsive: false
        };
        this.$elem = $(elem);
        this.setting = $.extend({}, this.default, option);
        if (this.setting.target === undefined) {
            this.$target = this.$elem.children();
        }
        else if (Array.isArray(this.setting.target)) {
            this.$target = this.setting.target.map(function (el) {
                return _this.$elem.find(el);
            });
        }
        else {
            this.$target = this.$elem.find(this.setting.target);
        }
        ;
        if (Array.isArray(this.$target)) {
            this.$target.forEach(function ($target) {
                _this.setHeight($target);
            });
        }
        else {
            this.setHeight(this.$target);
        }
        ;
        $(window).on('resize', function () {
            if (Array.isArray(_this.$target)) {
                _this.$target.forEach(function ($target) {
                    _this.setHeight($target);
                });
            }
            else {
                _this.setHeight(_this.$target);
            }
            ;
        });
    }
    ;
    FitHeight.prototype.setHeight = function ($target) {
        $target.css('height', 'auto');
        if (this.setting.responsive !== false && this.setting.responsive[util.viewport] !== 1) {
            var group = this.getGroup($target);
            group.forEach(function (set) {
                var max = 0;
                set.forEach(function (elem) {
                    var height = elem.offsetHeight;
                    if (max < height) {
                        max = height;
                    }
                    ;
                });
                $(set).css('height', max);
            });
        }
        ;
    };
    FitHeight.prototype.getGroup = function ($target) {
        var column = this.setting.column;
        if (this.setting.responsive !== false && this.setting.responsive[util.viewport]) {
            column = this.setting.responsive[util.viewport];
        }
        ;
        var group = [];
        var set = [];
        var full = 0;
        $target.each(function (i, elem) {
            set.push(elem);
            if (((i + full + 1) % column === 0 && (i + full + 1) / column > 0) || $(elem).closest('.c-Grid_Item.-full').length) {
                group.push(set);
                set = [];
                if ($(elem).closest('.c-Grid_Item.-full').length) {
                    full++;
                }
                ;
            }
            ;
        });
        if (set.length < column && set.length !== 0) {
            group.push(set);
        }
        ;
        return group;
    };
    return FitHeight;
}());
exports.FitHeight = FitHeight;
$.fn.fitHeight = function (option) {
    var _option = option ? option : {};
    return this.each(function (i, elem) {
        new FitHeight(elem, _option);
    });
};
