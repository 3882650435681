"use strict";
/*!
 *
 *  scroll-action.ts
 *
 */
Object.defineProperty(exports, "__esModule", { value: true });
var default_1 = /** @class */ (function () {
    function default_1() {
        var _this = this;
        this.$elem = $('.sw-SA');
        if (this.$elem.length) {
            var targets_1 = this.getTargets();
            var max_1 = targets_1.length;
            $(window).on('scroll.scrollAnim', function (e) {
                var _top = $(e.currentTarget).scrollTop();
                targets_1.forEach(function (val) {
                    if (!$(val.elem).is('.-run') && (_top + (util.wh / 2)) > val.top) {
                        $(val.elem).addClass('-run').trigger('run');
                        max_1--;
                    }
                    ;
                    if (max_1 === 0) {
                        $(window).off('.scrollAnim');
                    }
                    ;
                });
            });
            $(window).on('resize.scrollAnim', function () {
                targets_1 = _this.getTargets();
            });
            $(window).on('tab-changed', function () {
                targets_1 = _this.getTargets();
            });
            $(window).trigger('scroll.scrollAnim');
        }
        ;
    }
    default_1.prototype.getTargets = function () {
        var arr = [];
        this.$elem.each(function (i, elem) {
            arr.push({
                top: $(elem).offset().top,
                elem: elem
            });
        });
        return arr;
    };
    ;
    return default_1;
}());
exports.default = default_1;
