"use strict";
/*!
 *
 *  drop.ts
 *
 */
Object.defineProperty(exports, "__esModule", { value: true });
var default_1 = /** @class */ (function () {
    function default_1() {
        this.$drop = $('.sw-DropSet');
        this.lastScrollVal = 0;
        this.playDur = 1000;
        if (this.$drop.length) {
            this.lines = this.getLines();
            this.init();
        }
        ;
    }
    default_1.prototype.init = function () {
        var _this = this;
        $(window).on('scroll', function () {
            var top = $(window).scrollTop();
            if (_this.lastScrollVal - top < 0) {
                _this.lines.toDownShowLine.forEach(function (val, i) {
                    if (!$(val.elem).is('.-show') && top > val.num) {
                        $(val.elem).addClass('-show');
                    }
                    ;
                });
                _this.lines.playLine.forEach(function (val, i) {
                    if ($(val.elem).is('.-show') && !$(val.elem).is('.-play') && top > val.num) {
                        $(val.elem).addClass('-play');
                        setTimeout(function () {
                            if ($(val.elem).is('.-play')) {
                                $(val.elem).removeClass('-show -play');
                            }
                            ;
                        }, _this.playDur);
                    }
                    ;
                });
            }
            else if (_this.lastScrollVal - top > 0) {
                _this.lines.toUpShowLine.forEach(function (val, i) {
                    if (!$(val.elem).is('.-show') && top < val.num) {
                        $(val.elem).addClass('-show');
                    }
                    ;
                });
                _this.lines.playLine.forEach(function (val, i) {
                    if ($(val.elem).is('.-show') && !$(val.elem).is('.-play') && top < val.num) {
                        $(val.elem).addClass('-play');
                        setTimeout(function () {
                            if ($(val.elem).is('.-play')) {
                                $(val.elem).removeClass('-show -play');
                            }
                            ;
                        }, _this.playDur);
                    }
                    ;
                });
            }
            ;
            _this.lastScrollVal = top;
        }).on('resize tab-changed', function () {
            _this.lines = _this.getLines();
        });
    };
    default_1.prototype.getLines = function () {
        var lines = {
            toDownShowLine: [],
            toUpShowLine: [],
            playLine: []
        };
        this.$drop.each(function (i, elem) {
            var top = $(elem).offset().top;
            var h = $(elem).outerHeight();
            lines.toDownShowLine.push({
                elem: elem,
                num: top + h - util.wh
            });
            lines.toUpShowLine.push({
                elem: elem,
                num: top
            });
            lines.playLine.push({
                elem: elem,
                num: top - (util.wh / 2) - (h / 2)
            });
        });
        return lines;
    };
    return default_1;
}());
exports.default = default_1;
