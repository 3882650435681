"use strict";
/*!
 *
 *  products.js
 *
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.Other = exports.Gallery = void 0;
var default_1 = /** @class */ (function () {
    function default_1() {
        this.$gallery = $('.prod-Main_Photos');
        this.$other = $('.prod-Catalog.-slider .prod-Catalog_Inner');
        if (this.$gallery.length) {
            this.$gallery.each(function (i, elem) {
                new Gallery(elem);
            });
        }
        ;
        if (this.$other.length) {
            this.$other.each(function (i, elem) {
                new Other(elem);
            });
        }
        ;
    }
    return default_1;
}());
exports.default = default_1;
var Gallery = /** @class */ (function () {
    function Gallery(elem) {
        var _this = this;
        this.count = 2;
        this.$elem = $(elem);
        this.$slider = this.$elem.children('.prod-Main_Photos_Slider');
        this.$thumbs = this.$elem.children('.prod-Main_Photos_Thumbs');
        this.$slider.on('init', function () {
            if (--_this.count == 0) {
                _this.init();
            }
            ;
        });
        this.$thumbs.on('init', function () {
            if (--_this.count == 0) {
                _this.init();
            }
            ;
        });
        this.$slider.slick({
            slidesToShow: 1,
            slidesToScroll: 1,
            arrows: false,
            fade: true,
            asNavFor: this.$thumbs
        });
        this.$thumbs.slick({
            slidesToShow: 3,
            slidesToScroll: 1,
            asNavFor: this.$slider,
            dots: true,
            focusOnSelect: true
        });
    }
    Gallery.prototype.init = function () {
        this.$elem.closest('.prod-Main').addClass('-init');
    };
    return Gallery;
}());
exports.Gallery = Gallery;
var Other = /** @class */ (function () {
    function Other(elem) {
        var _this = this;
        this.setting = {};
        this.$elem = $(elem);
        this.setting = {
            slidesToShow: 3,
            slidesToScroll: 1,
            arrows: true,
            appendArrows: $(elem).next('.prod-Catalog_Control'),
            responsive: [
                {
                    breakpoint: 768,
                    settings: {
                        slidesToShow: 3,
                        slidesToScroll: 1,
                        arrows: true,
                        appendArrows: $(elem).next('.prod-Catalog_Control')
                    }
                },
                {
                    breakpoint: 767,
                    settings: 'unslick'
                }
            ]
        };
        this.$elem.slick(this.setting);
        $(window).on('viewportChanged', function () {
            if (util.viewport !== 'phone' && !_this.$elem.children('.slick-list').length) {
                _this.$elem.slick(_this.setting);
            }
            ;
        });
    }
    return Other;
}());
exports.Other = Other;
