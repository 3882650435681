"use strict";
/*!
 *
 *  wiper-card.ts
 *
 */
Object.defineProperty(exports, "__esModule", { value: true });
var default_1 = /** @class */ (function () {
    function default_1() {
        var _this = this;
        this.$card = $('.wpr-Card');
        this.$catalog = $('.wpr-Catalog');
        if (this.$card.length) {
            this.$card.each(function (i, elem) {
                $(elem).find('.wpr-Card_More_Btn').on('click', _this.toggle);
            });
        }
        ;
        if (this.$catalog.length) {
            this.$catalog.fitHeight({
                target: '.wpr-Card_Body',
                column: 3,
                responsive: {
                    'phone': 1,
                    'tablet': 3,
                    'desktop': 3,
                    'largeDesktop': 3
                }
            });
        }
        ;
    }
    default_1.prototype.toggle = function (e) {
        e.preventDefault();
        var $more = $(e.currentTarget).closest('.wpr-Card_More');
        var $inner = $more.find('.wpr-Card_More_Inner');
        if ($more.is('[aria-expanded="false"]')) {
            $more.attr('aria-expanded', 'true');
            $inner.stop(true, false).slideDown(500, 'easeOutQuart');
        }
        else {
            $more.attr('aria-expanded', 'false');
            $inner.stop(true, false).slideUp(500, 'easeOutQuart');
        }
        ;
    };
    return default_1;
}());
exports.default = default_1;
