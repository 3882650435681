"use strict";
/*!
 *
 *  top.js
 *
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.Top = exports.Hero = void 0;
var Hero = /** @class */ (function () {
    function Hero() {
        this.$hero = $('#TopHero');
        if (this.$hero.length) {
            this.$video = $('.tp-Video_Main');
            this.lang = new Lang();
            this.clip = new clipAnimation(this.$hero, this.$video);
            new Loading(this);
            if ($('html').is('.not-work-webp')) {
                this.$video.attr('poster', '/assets/img/top/poster.png');
            }
            ;
        }
        ;
    }
    Hero.prototype.play = function () {
        this.$hero.addClass('-play');
        $('.tp-Video').addClass('-play');
        this.clip.play();
    };
    return Hero;
}());
exports.Hero = Hero;
var clipAnimation = /** @class */ (function () {
    function clipAnimation(base, video) {
        this.played = false;
        this.w = 0;
        this.h = 0;
        this.duration = 3000;
        this.$base = base;
        this.$video = video;
        this.$frame = $('.tp-Video_Frame');
        this.init();
    }
    clipAnimation.prototype.init = function () {
        var _this = this;
        this.setSize();
        $(window).on('resize', function () {
            _this.setSize();
            if (_this.played) {
                var end_1 = _this.getPos(180);
                if (util.canUseClipPath) {
                    _this.$frame.css('clip-path', _this.getPath(end_1));
                }
                ;
            }
            ;
        });
        var end = this.getPos(0);
        this.$frame.css({
            'opacity': '1',
            'visibility': 'visible',
            'clip-path': this.getPath(end)
        });
        if (!util.canUseClipPath) {
            this.$frame.css({
                'display': 'none'
            });
        }
        ;
    };
    clipAnimation.prototype.play = function () {
        var _this = this;
        if (!this.played) {
            if (util.canUseClipPath) {
                this.$video[0].currentTime = 1;
                var d_1 = 0;
                var currentTime_1 = 0;
                var move_1 = setInterval(function () {
                    d_1 = 180 * _this.easing(currentTime_1 / _this.duration);
                    var end = _this.getPos(d_1);
                    // console.log(this.getPath(end));
                    _this.$frame.css('clip-path', _this.getPath(end));
                    currentTime_1 += 10;
                    if (currentTime_1 > _this.duration) {
                        clearInterval(move_1);
                        _this.played = true;
                    }
                    ;
                }, 10);
            }
            else {
                this.$frame.fadeIn(700);
                this.played = true;
            }
            ;
        }
        ;
    };
    clipAnimation.prototype.setSize = function () {
        this.h = this.$frame.outerHeight();
        if (util.viewport !== 'phone' && this.h < 568) {
            this.h = 568;
        }
        ;
        if (util.viewport === 'phone' && this.h < 524) {
            this.h = 524;
        }
        ;
        this.w = this.h * 2;
        var bw = this.$base.outerWidth();
        var bh = this.$base.outerHeight();
        var mt = (bh - this.h) / 2;
        this.$frame.css({
            'width': this.w,
            'margin-top': mt
        });
        if (util.canUseClipPath) {
            var left = util.viewport !== 'phone' ? 0 : util.ww * 0.25;
            this.$video.css({
                left: left,
                width: (util.viewport !== 'tablet' && util.viewport !== 'phone') ? (bw / 2 + 376) : bw * 1.1,
                height: bh
            });
        }
        else {
            this.$video.css({
                width: 1920 / 1080 * bh,
                height: bh
            });
            this.$frame.css({
                'border-top-left-radius': this.w / 2,
                'border-top-right-radius': this.w / 2,
                'overflow': 'hidden'
            });
        }
        ;
        // this.$path
        // 	.attr('d', `M${w},${h}C${w},${y2},${x2},0,${h},0S0,${y2},0,${h}H${w}z`)
        // 	.css('transform-origin', `${w / 2}px ${h}px`);
    };
    clipAnimation.prototype.easing = function (i) {
        return Math.sqrt(1 - Math.pow(i - 1, 2));
    };
    clipAnimation.prototype.getPos = function (d) {
        var r = d * (Math.PI / 180);
        var x = Math.abs((Math.round(Math.cos(r) * this.h * 1000) / 1000) - this.h);
        var y = Math.abs((Math.round(Math.sin(r) * this.h * 1000) / 1000) - this.h);
        return x + " " + y;
    };
    ;
    clipAnimation.prototype.getPath = function (end) {
        return "path('M" + this.h + "," + this.h + " L0," + this.h + " A" + this.h + " " + this.h + ", 0, 0, 1, " + end + " z')";
    };
    return clipAnimation;
}());
var Lang = /** @class */ (function () {
    function Lang() {
        var _this = this;
        this.$lang = $('.tp-Hero_Lang_Inner');
        this.$label = this.$lang.children('.tp-Hero_Lang_Label');
        this.$list = this.$lang.children('.tp-Hero_Lang_List');
        this.$lang.on('mouseenter', function () {
            if (!util.isTouchDevice) {
                _this.open();
            }
            ;
        }).on('mouseleave', function () {
            if (!util.isTouchDevice) {
                _this.close();
            }
            ;
        });
        this.$label.on('click', function () {
            if (util.isTouchDevice) {
                if (_this.$lang.is('[aria-expanded="false"]')) {
                    _this.open();
                }
                else {
                    _this.close();
                }
                ;
            }
            ;
        });
        $(document).on('click', function (e) {
            if (util.isTouchDevice) {
                if (!$(e.target).closest('.tp-Hero_Lang').length) {
                    _this.close();
                }
                ;
            }
            ;
        });
    }
    Lang.prototype.open = function () {
        this.$lang.attr('aria-expanded', 'true');
        this.$list.stop(true, false).slideDown(300);
    };
    Lang.prototype.close = function () {
        this.$lang.attr('aria-expanded', 'false');
        this.$list.stop(true, false).slideUp(300);
    };
    return Lang;
}());
var Loading = /** @class */ (function () {
    function Loading(hero) {
        this.$loading = $('.tp-Hero_Loading');
        this.w = 0;
        this.h = 0;
        this.d = 0;
        this.duration = 500;
        this.count = 0;
        this.loaded = false;
        this.hero = hero;
        // this.video = document.createElement('video');
        this.src = util.viewport !== 'phone' ? this.hero.$video.data('src') : this.hero.$video.data('src-sm');
        // this.src = this.hero.$video.attr('src');
        this.init();
    }
    Loading.prototype.init = function () {
        // this.w = this.$loading.outerWidth();
        // this.h = this.w / 2;
        // let end = this.getPos(0);
        // this.$loading.css({
        // 	'visibility': 'visible',
        // 	'clip-path': this.getPath(end)
        // });
        this.loadStart();
    };
    Loading.prototype.loadStart = function () {
        var _this = this;
        this.hero.$video[0].addEventListener('loadstart', function () {
            // console.log('loadstart')
            _this.handleEvent();
        }, false);
        this.hero.$video[0].addEventListener('loadedmetadata', function () {
            // console.log('loadedmetadata')
            _this.handleEvent();
        }, false);
        this.hero.$video[0].addEventListener('loadeddata', function () {
            // console.log('loadeddata')
            _this.handleEvent();
        }, false);
        this.hero.$video[0].addEventListener('canplay', function () {
            // console.log('canplay')
            _this.handleEvent();
        }, false);
        // this.hero.$video[0].addEventListener('canplaythrough', () => {
        // 	// console.log('canplaythrough')
        // 	this.handleEvent();
        // }, false);
        setTimeout(function () {
            if (_this.count !== 4) {
                _this.count = 4;
                // this.progress();
                _this.loadend();
            }
            ;
        }, 10000);
        this.hero.$video.attr('src', this.src + '?_' + new Date().getTime());
        this.hero.$video[0].load();
    };
    Loading.prototype.loadend = function () {
        this.loaded = true;
        this.hero.$video.attr('src', this.src);
        this.hide();
    };
    // progress() {
    // 	if (!this.loaded) {
    // 		let d = 0;
    // 		let currentTime = 0;
    // 		let move = setInterval(() => {
    // 			d = this.d + (180 / 4) * this.easing(currentTime / this.duration);
    // 			let end = this.getPos(d);
    // 			this.$loading.css('clip-path', this.getPath(end));
    // 			currentTime += 10;
    // 			if (currentTime > this.duration) {
    // 				clearInterval(move);
    // 				this.d = d;
    // 				if (this.d === 180) {
    // 					this.loaded = true;
    // 					this.hero.$video.attr('src', this.src);
    // 					this.hide();
    // 				};
    // 			};
    // 		}, 10);
    // 	};
    // }
    Loading.prototype.easing = function (i) {
        return Math.sqrt(1 - Math.pow(i - 1, 2));
    };
    Loading.prototype.getPos = function (d) {
        var r = d * (Math.PI / 180);
        var x = Math.abs((Math.round(Math.cos(r) * this.h * 1000) / 1000) - this.h);
        var y = Math.abs((Math.round(Math.sin(r) * this.h * 1000) / 1000) - this.h);
        return x + " " + y;
    };
    Loading.prototype.getPath = function (end) {
        return "path('M" + this.h + "," + this.h + " L0," + this.h + " A" + this.h + " " + this.h + ", 0, 0, 1, " + end + " z')";
    };
    Loading.prototype.handleEvent = function () {
        this.count++;
        if (this.count == 4) {
            this.loadend();
        }
        // setTimeout(() => {
        // 	this.progress();
        // }, this.duration * (this.count - 1));
    };
    Loading.prototype.hide = function () {
        var _this = this;
        this.$loading.fadeOut(500, function () {
            _this.hero.play();
        });
    };
    return Loading;
}());
var Top = /** @class */ (function () {
    function Top() {
        if ($('#TopHero').length) {
            this.$bnr = $('.tp-Bnr_Slider');
            this.$bnr.slick({
                slidesToShow: 3,
                slidesToScroll: 1,
                infinite: true,
                dots: false,
                arrows: true,
                appendArrows: $('.tp-Bnr_Controls'),
                responsive: [
                    {
                        breakpoint: 768,
                        settings: {
                            slidesToShow: 2,
                            slidesToScroll: 1,
                            dots: true,
                            arrows: false,
                            appendDots: $('.tp-Bnr_Controls')
                            // variableWidth: true
                        }
                    },
                    {
                        breakpoint: 576,
                        settings: {
                            slidesToShow: 1,
                            slidesToScroll: 1,
                            dots: true,
                            arrows: false,
                            appendDots: $('.tp-Bnr_Controls')
                            // variableWidth: true
                        }
                    }
                ]
            });
            $('.tp-Product_Box').on('mouseenter', function (e) {
                $(e.currentTarget).prev('.tp-Product_Media').addClass('-hover');
            }).on('mouseleave', function (e) {
                $(e.currentTarget).prev('.tp-Product_Media').removeClass('-hover');
            });
            this.$wiper = $('.tp-Wiper_Media');
            this.$wiper.wiper({
                frame: '.tp-Wiper_Media_Frame'
            });
        }
        ;
    }
    return Top;
}());
exports.Top = Top;
