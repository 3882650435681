"use strict";
/*!
 *
 *  menu.ts
 *
 */
Object.defineProperty(exports, "__esModule", { value: true });
var default_1 = /** @class */ (function () {
    function default_1() {
        var _this = this;
        this.$menu = $('#Menu');
        this.$btn = $('.st-MenuBtn');
        this.$overlay = $('.st-Overlay');
        this.top = 0;
        this.checkViewport();
        $(window).on('viewportChanged', function () {
            _this.checkViewport();
        });
        this.$btn.on('click', function (e) {
            e.preventDefault();
            if (_this.$menu.is('[aria-expanded="true"]')) {
                _this.close(e.currentTarget);
            }
            else {
                _this.open(e.currentTarget);
            }
            ;
        });
        $(document).on('click', function (e) {
            if (_this.$menu.is('[aria-expanded="true"]') && (util.viewport === 'phone' || util.viewport === 'tablet')) {
                if (!$(e.target).closest('.st-Menu_Body').length
                    && !$(e.target).closest('.st-Menu_Head').length
                    && !$(e.target).closest('.st-MenuBtn').length) {
                    _this.close($('.st-MenuBtn[aria-pressed="true"]')[0]);
                }
                ;
            }
            ;
        });
    }
    default_1.prototype.open = function (btn) {
        $(btn)
            .attr('aria-pressed', 'true')
            .children('.st-MenuBtn_Label')
            .text('CLOSE');
        this.$menu.attr({
            'aria-expanded': 'true',
            'aria-hidden': 'false'
        });
        $('html').css({
            overflow: 'hidden'
        });
        document.addEventListener('touchmove', this.scrollControll, {
            passive: false
        });
    };
    default_1.prototype.close = function (btn) {
        $(btn)
            .attr('aria-pressed', 'false')
            .children('.st-MenuBtn_Label')
            .text('MENU');
        this.$menu.attr({
            'aria-expanded': 'false',
            'aria-hidden': 'true'
        });
        $('html').css({
            overflow: ''
        });
        document.removeEventListener('touchmove', this.scrollControll);
    };
    default_1.prototype.scrollControll = function (e) {
        var $inner = $(e.target).closest('.st-Menu_Body');
        if ($inner.length > 0) {
            if ($inner.get(0).scrollHeight > $inner.innerHeight()) {
                e.stopPropagation();
            }
            else {
                e.preventDefault();
            }
            ;
        }
        else {
            e.preventDefault();
        }
    };
    default_1.prototype.checkViewport = function () {
        var _this = this;
        this.$menu.removeClass('-transition');
        if (util.viewport !== 'phone' && util.viewport !== 'tablet') {
            this.$menu.attr({
                'aria-expanded': 'true',
                'aria-hidden': 'false'
            });
        }
        else {
            this.$menu.attr({
                'aria-expanded': 'false',
                'aria-hidden': 'false'
            });
            setTimeout(function () {
                _this.$menu.addClass('-transition');
            }, 700);
        }
        ;
    };
    return default_1;
}());
exports.default = default_1;
;
