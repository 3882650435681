"use strict";
/*!
 *
 *  parallax.ts
 *
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.Parallax = void 0;
var Parallax = /** @class */ (function () {
    function Parallax(elem, option) {
        this.start = 0;
        this.end = 0;
        this.span = 0;
        this.ratio = 0;
        this.innerClass = '.c-Parallax_Inner';
        this.$elem = $(elem);
        if (option.inner) {
            this.innerClass = option.inner;
        }
        ;
        this.$inner = this.$elem.find(this.innerClass);
        this.$img = this.$elem.find('img');
        this.setting();
        this.init();
    }
    Parallax.prototype.init = function () {
        var _this = this;
        $(window).on('scroll', function (e) {
            var _top = $(e.currentTarget).scrollTop();
            if (_top > _this.start && _top <= _this.end) {
                var y = _this.ratio * (_top - _this.end);
                _this.$inner.css('transform', "translate(0, " + y + "px)");
            }
            ;
        }).on('resize', function () {
            _this.setting();
        });
    };
    Parallax.prototype.setting = function () {
        this.start = this.$elem.offset().top - (util.wh / 2);
        this.end = this.$elem.offset().top;
        this.span = this.$img.height() - this.$elem.height();
        this.ratio = this.span / (util.wh / 2);
    };
    return Parallax;
}());
exports.Parallax = Parallax;
$.fn.parallax = function (option) {
    var opt = option ? option : {};
    return this.each(function (i, elem) {
        new Parallax(elem, opt);
    });
};
