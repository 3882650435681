<template>
	<div class="mtc-Nav">
		<div class="mtc-Nav_Return"><a href="#" @click.prevent="prev">戻る</a></div>
		<transition name="fade">
			<div class="mtc-Nav_More" v-show="state === 5"><a href="#" @click.prevent="reset">もう一度診断する</a></div>
		</transition>
	</div>
</template>

<script>
	export default {
		props: ['state'],
		methods: {
			prev: function() {
				this.$emit('prev');
			},
			reset: function() {
				this.$emit('reset');
			}
		}
	}
</script>