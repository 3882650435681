"use strict";
/*!
 *
 *  wiper.ts
 *
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.Wiper = void 0;
var Wiper = /** @class */ (function () {
    function Wiper(elem, option) {
        this.played = false;
        this.w = 0;
        this.h = 0;
        this.duration = 1500;
        this.$elem = $(elem);
        this.$frame = this.$elem.find(option.frame);
        if (option.duration) {
            this.duration = option.duration;
        }
        ;
        this.init();
    }
    Wiper.prototype.init = function () {
        var _this = this;
        this.setSize();
        var end = this.getPos(0);
        if (util.canUseClipPath) {
            this.$frame.css('clip-path', this.getPath(end));
        }
        ;
        $(window).on('resize', function () {
            _this.setSize();
            if (_this.played) {
                var end_1 = _this.getPos(180);
                if (util.canUseClipPath) {
                    _this.$frame.css('clip-path', _this.getPath(end_1));
                }
                ;
            }
            ;
        });
        this.$elem.on('run', function () {
            _this.play();
        });
    };
    Wiper.prototype.setSize = function () {
        this.w = this.$elem.width();
        this.h = this.w / 2;
        var vh = this.$elem.height();
        this.$frame.css({
            'width': this.w,
            'height': this.h,
            'margin-top': util.viewport !== 'phone' ? (this.h - vh) / -2 : 0
        });
        if (!util.canUseClipPath) {
            this.$frame.css({
                'border-top-left-radius': this.w / 2,
                'border-top-right-radius': this.w / 2,
                'overflow': 'hidden'
            });
        }
    };
    Wiper.prototype.play = function () {
        var _this = this;
        if (!this.played) {
            if (util.canUseClipPath) {
                var d_1 = 0;
                var currentTime_1 = 0;
                var move_1 = setInterval(function () {
                    d_1 = 180 * _this.easing(currentTime_1 / _this.duration);
                    var end = _this.getPos(d_1);
                    _this.$frame.css('clip-path', _this.getPath(end));
                    currentTime_1 += 10;
                    if (currentTime_1 > _this.duration) {
                        clearInterval(move_1);
                        _this.played = true;
                    }
                    ;
                }, 10);
            }
            else {
                this.$frame.fadeIn(700);
                this.played = true;
            }
            ;
        }
        ;
    };
    Wiper.prototype.easing = function (i) {
        return Math.sqrt(1 - Math.pow(i - 1, 2));
    };
    Wiper.prototype.getPos = function (d) {
        var r = d * (Math.PI / 180);
        var x = Math.abs((Math.round(Math.cos(r) * this.h * 1000) / 1000) - this.h);
        var y = Math.abs((Math.round(Math.sin(r) * this.h * 1000) / 1000) - this.h);
        return x + " " + y;
    };
    Wiper.prototype.getPath = function (end) {
        return "path('M" + this.h + "," + this.h + " L0," + this.h + " A" + this.h + " " + this.h + ", 0, 0, 1, " + end + " z')";
    };
    return Wiper;
}());
exports.Wiper = Wiper;
$.fn.wiper = function (option) {
    return this.each(function (i, elem) {
        new Wiper(elem, option);
    });
};
