var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "section",
    { class: { "mtc-Result": true, "-current": _vm.state === 5 } },
    [
      _c("h2", { staticClass: "mtc-Result_Title" }, [
        _vm._v("あなたにぴったりのガラコは・・・")
      ]),
      _vm._v(" "),
      _vm.product
        ? _c("section", { staticClass: "prod-Main" }, [
            _c("div", { staticClass: "prod-Main_Inner" }, [
              _c("div", { staticClass: "prod-Main_Tag" }, [
                _vm._v(_vm._s(_vm.product.type) + "タイプ")
              ]),
              _vm._v(" "),
              _c("p", {
                staticClass: "prod-Main_Lead",
                domProps: { innerHTML: _vm._s(_vm.product.lead) }
              }),
              _vm._v(" "),
              _c("h3", {
                staticClass: "prod-Main_Name",
                domProps: { innerHTML: _vm._s(_vm.getName(_vm.product.name)) }
              }),
              _vm._v(" "),
              _c("p", { domProps: { innerHTML: _vm._s(_vm.product.desc) } }),
              _vm._v(" "),
              _c("p", { staticClass: "c-Btn -primary -xl -sp-c" }, [
                _c(
                  "a",
                  {
                    attrs: {
                      href: "/products/" + _vm.product.basename + ".html"
                    }
                  },
                  [_vm._v("商品詳細ページを見る")]
                )
              ])
            ]),
            _vm._v(" "),
            _c("figure", { staticClass: "prod-Main_Photo" }, [
              _c("div", {
                staticClass: "prod-Main_Photo_Inner",
                domProps: { innerHTML: _vm._s(_vm.productImg) }
              })
            ])
          ])
        : _vm._e()
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }