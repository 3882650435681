"use strict";
/*!
 *
 *  tab.js
 *
 */
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
var default_1 = /** @class */ (function () {
    function default_1(option) {
        var _this = this;
        this.default = {
            elem: '.c-Tab',
            navClass: '.c-Tab_Nav',
            cntClass: '.c-Tab_Body'
        };
        this.setting = __assign(__assign({}, this.default), option);
        this.$elem = $(this.setting.elem);
        if (this.$elem.length) {
            this.$elem.each(function (i, elem) {
                new TabCore(elem, _this.setting);
            });
        }
    }
    return default_1;
}());
exports.default = default_1;
;
var TabCore = /** @class */ (function () {
    function TabCore(elem, setting) {
        var _this = this;
        this.hasHashCurrent = false;
        this.$nav = $(elem).children(setting.navClass);
        this.hash = util.hash;
        if (this.hash.search('--') !== -1) {
            this.hash = this.hash.split('--')[0];
        }
        ;
        this.$a = this.$nav.find('a').filter(function (i, elem) {
            return $(elem).is('[href^="#"]');
        });
        this.$contents = $(elem).children(setting.cntClass);
        this.$a.each(function (i, item) {
            var _href = $(item).attr('href');
            if (_href === _this.hash) {
                _this.hasHashCurrent = true;
            }
            ;
        });
        this.setDefault();
        this.setEvent();
    }
    TabCore.prototype.setDefault = function () {
        var _this = this;
        var $link;
        var $contents;
        this.$a.each(function (i, elem) {
            var _href = $(elem).attr('href');
            if (_this.hasHashCurrent) {
                if (_href === _this.hash) {
                    $link = $(elem);
                    $contents = $(_href);
                }
                ;
            }
            else {
                if (i === 0) {
                    $link = $(elem);
                    $contents = $(_href);
                }
                ;
            }
            ;
        });
        this.$a.attr('aria-selected', 'false');
        this.$contents.children().attr('aria-hidden', 'true');
        $link.attr('aria-selected', 'true');
        $contents.attr('aria-hidden', 'false');
        if (this.hasHashCurrent) {
            $(window).on('load', function () {
                history.replaceState(null, null, util.hash);
            });
        }
        ;
    };
    TabCore.prototype.setEvent = function () {
        var _this = this;
        this.$a.on('click', function (e) {
            e.preventDefault();
            e.stopPropagation();
            if ($(e.currentTarget).is('[aria-selected="true"]'))
                return false;
            var _href = $(e.currentTarget).attr('href');
            _this.$nav.find('[aria-selected="true"]').attr('aria-selected', 'false');
            _this.$contents.children('[aria-hidden="false"]').attr('aria-hidden', 'true');
            $(e.currentTarget).attr('aria-selected', 'true');
            $(_href).attr('aria-hidden', 'false');
            $(window).trigger('tab-changed');
        });
    };
    return TabCore;
}());
