<template>
	<section :class="{'mtc-Result': true, '-current': state === 5}">
		<h2 class="mtc-Result_Title">あなたにぴったりのガラコは・・・</h2>
		<section class="prod-Main" v-if="product">
			<div class="prod-Main_Inner">
				<div class="prod-Main_Tag">{{ product.type }}タイプ</div>
				<p class="prod-Main_Lead" v-html="product.lead"></p>
				<h3 class="prod-Main_Name" v-html="getName(product.name)"></h3>
				<p v-html="product.desc"></p>
				<p class="c-Btn -primary -xl -sp-c"><a :href="'/products/'+ product.basename +'.html'">商品詳細ページを見る</a></p>
			</div>
			<figure class="prod-Main_Photo">
				<div class="prod-Main_Photo_Inner" v-html="productImg"></div>
			</figure>
		</section>
	</section>
</template>

<script>
import 'polyfill';
import objectFitImages from 'object-fit-images';
export default {
	props: ['answer', 'state', 'products'],
	data: function() {
		return {
			product: undefined,
			answers: {
				'AAAAA': 'glaco_roll_on_hayadeki',
				'AAAAB': 'glaco_mist',
				'AAABA': 'glaco_q',
				'AAABB': 'glaco_mist',
				'AABAA': 'glaco_roll_on_large',
				'AABAB': 'glaco_wjet',
				'AABBA': 'ultra_glaco',
				'AABBB': 'glaco_quick',
				'ABAAA': 'glaco_roll_on_large',
				'ABAAB': 'glaco_wjet',
				'ABABA': 'glaco_wiper_pwr_aero_smooth',
				'ABABB': 'glaco_roll_on_hayadeki',
				'ABBAA': 'glaco_blave',
				'ABBAB': 'glaco_washer_pouch_2l',
				'ABBBA': 'ultra_glaco',
				'ABBBB': 'glaco_quick',
				'BAAAA': 'glaco_roll_on_large',
				'BAAAB': 'glaco_mist',
				'BAABA': 'glaco_blave',
				'BAABB': 'glaco_mist',
				'BABAA': 'glaco_roll_on_hayadeki',
				'BABAB': 'glaco_wjet',
				'BABBA': 'ultra_glaco',
				'BABBB': 'glaco_quick',
				'BBAAA': 'glaco_roll_on_hayadeki',
				'BBAAB': 'glaco_wjet',
				'BBABA': 'glaco_wiper_pwr_blade',
				'BBABB': 'glaco_roll_on_large',
				'BBBAA': 'glaco_q',
				'BBBAB': 'glaco_washer_pouch_2l',
				'BBBBA': 'ultra_glaco',
				'BBBBB': 'glaco_quick',
				'CAAAA': 'glaco_roll_on_hayadeki',
				'CAAAB': 'glaco_mist',
				'CAABA': 'glaco_q',
				'CAABB': 'glaco_mist',
				'CABAA': 'glaco_roll_on_large',
				'CABAB': 'glaco_wjet',
				'CABBA': 'ultra_glaco',
				'CABBB': 'glaco_quick',
				'CBAAA': 'glaco_roll_on_large',
				'CBAAB': 'glaco_wjet',
				'CBABA': 'glaco_wiper_pwr_blade',
				'CBABB': 'glaco_roll_on_hayadeki',
				'CBBAA': 'glaco_blave',
				'CBBAB': 'glaco_washer_strong',
				'CBBBA': 'ultra_glaco',
				'CBBBB': 'glaco_quick'
			}
		}
	},
	computed: {
		productImg: function() {
			if (this.product) {
				return '<img src="/assets/img/products/' + this.product.basename + '_1.png" alt="" class="sw-ObjectFit -scale-down">';
			} else {
				return '';
			};
		}
	},
	watch: {
		answer: function() {
			let self = this;
			this.product = this.products.find(function(val) {
				return val.basename === self.answers[self.answer];
			});
		},
		productImg: function() {
			setTimeout(function() {
				objectFitImages('img.sw-ObjectFit', { watchMQ: true });
			}, 1);
		}
	},
	methods: {
		getName: function(name) {
			return name
					.replace('ガラコウォッシャー ', 'ガラコウォッシャー<br>')
					.replace('ガラコワイパー', 'ガラコワイパー<br>');
		}
	}
}
</script>