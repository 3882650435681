"use strict";
/*!
 *
 *  widget.ts
 *
 */
Object.defineProperty(exports, "__esModule", { value: true });
var default_1 = /** @class */ (function () {
    function default_1() {
        this.$local = $('.c-Local');
        this.$target = $('#Header');
        this.top = 0;
        this.height = 0;
        this.isVisible = false;
        this.isStatic = false;
        this.visibleLine = 0;
        if (this.$local.length) {
            this.init();
        }
        ;
    }
    default_1.prototype.init = function () {
        var _this = this;
        this.top = this.$local.offset().top;
        if (util.wh < this.top) {
            this.$local.height(this.$local.height());
            this.visibleLine = this.getVisibleLine();
            this.$local.wrapInner('<div class="c-Local_Wrapper" />');
            this.$local.addClass('-init');
            this.height = this.$local.children('.c-Local_Wrapper').outerHeight();
            $(window).on('load resize', function () {
                _this.$local.height(_this.$local.height());
                _this.visibleLine = _this.getVisibleLine();
                _this.top = _this.$local.offset().top;
                _this.height = _this.$local.children('.c-Local_Wrapper').outerHeight();
            }).on('scroll', function (e) {
                _this.scroll(e);
            });
        }
        ;
    };
    default_1.prototype.scroll = function (e) {
        var top = $(e.currentTarget).scrollTop();
        if (!this.isVisible && top > this.visibleLine) {
            this.isVisible = true;
            this.$local.attr('aria-hidden', 'false');
        }
        ;
        if (this.isVisible && top <= this.visibleLine) {
            this.isVisible = false;
            this.$local.attr('aria-hidden', 'true');
        }
        ;
        if (!this.isStatic && (top + util.wh) >= (this.top + this.height)) {
            this.isStatic = true;
            this.$local.addClass('-static');
        }
        ;
        if (this.isStatic && (top + util.wh) < (this.top + this.height)) {
            this.isStatic = false;
            this.$local.removeClass('-static');
        }
        ;
    };
    default_1.prototype.getVisibleLine = function () {
        // return this.$target.offset().top + this.$target.height();
        return this.$target.height();
    };
    return default_1;
}());
exports.default = default_1;
