<template>
	<div class="mtc-Step">
		<ol class="mtc-Step_List" :style="position">
			<li class="mtc-Step_Item">
				<span class="mtc-Step_Item_Label">Start</span>
			</li>
			<li :class="{
				'mtc-Step_Item': true,
				'-active': state >= 0,
				'-current': state === 0
			}">
				<span class="mtc-Step_Item_Label">車のタイプ</span>
			</li>
			<li :class="{
				'mtc-Step_Item': true,
				'-active': state >= 1,
				'-current': state === 1
			}">
				<span class="mtc-Step_Item_Label">乗車頻度</span>
			</li>
			<li :class="{
				'mtc-Step_Item': true,
				'-active': state >= 2,
				'-current': state === 2
			}">
				<span class="mtc-Step_Item_Label">保管場所</span>
			</li>
			<li :class="{
				'mtc-Step_Item': true,
				'-active': state >= 3,
				'-current': state === 3
			}">
				<span class="mtc-Step_Item_Label">洗車頻度</span>
			</li>
			<li :class="{
				'mtc-Step_Item': true,
				'-active': state >= 4,
				'-current': state === 4
			}">
				<span class="mtc-Step_Item_Label">洗車方法</span>
			</li>
			<li :class="{
				'mtc-Step_Item': true,
				'-active': state >= 5,
				'-current': state === 5
			}">
				<span class="mtc-Step_Item_Label">結果</span>
			</li>
		</ol>
	</div>
</template>

<script>
	export default {
		props: ['state'],
		data: function() {
			return {
				position: {}
			}
		},
		mounted: function() {
			let self = this;
			this.setPosition();
			window.addEventListener('viewportChanged', function() {
				self.setPosition();
			}, true);
		},
		watch: {
			state: function() {
				this.setPosition();
			}
		},
		methods: {
			setPosition: function() {
				if (util.viewport === 'phone') {
					// let pos = this.state !== 5 ? this.state : 4;
					let pos = this.state;
					this.position = {
						transform: 'translate('+ (-100 / 7) * pos +'%, 0)'
					};
				} else {
					this.position = {};
				};
			}
		}
	}
</script>